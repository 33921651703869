import * as React from "react"
import './styles.scss'
import HomePage from './home'


const IndexPage = () => {
    return (
        <main>
            <head>
                <meta name="google-site-verification" content="iACVVVrDqbPvFEs_-_slMHV24jDvpdTKMrgHVVq-too" />
            </head>
            <HomePage />
        </main>
    )
}

export default IndexPage